<template>
  <div class="card">
    <Head :name="'插卡取电面板'" :add="'/pc/ProCard'" :adds="'/pc/ProCardParam'" :color="true" />
    <div class="banner">
      <div class="bannerBox">
        <div class="bannerTit">插卡取电面板</div>
        <div class="bannerText">咔哒一下，就有电</div>
        <div class="bannerUl">
          <div class="bannerLi">
            <img src="../../../assets/product/cardIcon1.png" class="bliImg" alt />
            <div class="bliText">延时断电，安全方便</div>
          </div>
          <div class="bannerLi">
            <img src="../../../assets/product/cardIcon2.png" class="bliImg" alt />
            <div class="bliText">优质材质，坚固耐用</div>
          </div>
          <div class="bannerLi">
            <img src="../../../assets/product/cardIcon3.png" class="bliImg" alt />
            <div class="bliText">30A 大功率</div>
          </div>
          <div class="bannerLi">
            <img src="../../../assets/product/cardIcon4.png" class="bliImg" alt />
            <div class="bliText">多重防护，保障安全</div>
          </div>
        </div>
      </div>
    </div>

    <div class="color">
      <div class="colorBox">
        <div class="colorTit">多种颜色，多种精彩</div>
      </div>
      <div class="txt txt1">白色</div>
      <div class="txt txt2">黑色</div>
      <div class="txt txt3">浅香槟金</div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 8rem;
  background: url("../../../assets/product/cardBanner.png");
  background-size: 100% 100%;
  .bannerBox {
    width: 12rem;
    height: 8rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    color: #333;
    position: relative;
    .bannerTit {
      margin-top: 1.39rem;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
    }
    .bannerText {
      margin-top: 0.24rem;
    }
    .bannerUl {
      position: absolute;
      left: calc((100% - 12rem) / 2);
      bottom: 0.4rem;
      width: 12rem;
      height: 1.67rem;
      background-color: #333;
      display: flex;
      font-weight: bold;
      color: #ffffff;

      .bannerLi {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .bliImg {
          width: 0.41rem;
          height: 0.41rem;
        }
        &:nth-of-type(2) .bliImg {
          width: 0.45rem;
          height: 0.39rem;
        }
        &:nth-of-type(3) .bliImg {
          width: 0.47rem;
          height: 0.36rem;
        }
        &:nth-of-type(4) .bliImg {
          width: 0.38rem;
          height: 0.41rem;
        }
        .bliText {
          margin-top: 0.24rem;
          font-size: 0.24rem;
        }
      }
    }
  }
}

.color {
  width: 100%;
  height: 8rem;
  background: url("../../../assets/product/cardColor.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 0.18rem;
  font-weight: 400;
  color: #ff9000;
  .txt {
    position: absolute;
    top: 5.9rem;
  }
  .txt1 {
    left: 3.02rem;
  }
  .txt2 {
    left: 9.42rem;
  }
  .txt3 {
    right: 2.82rem;
  }
  .colorBox {
    width: 12rem;
    height: 0.78rem;
    margin: 0 auto;
    margin-top: 0.6rem;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.001) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 0.001) 100%
    );
    .colorTit {
      width: 100%;
      font-size: 0.36rem;
      line-height: 0.78rem;
      font-weight: 800;
      color: #fff;
    }
  }
}
</style>
